import React from 'react';

import { HtmlTextWrapper } from '@/core/features';
import { Link } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './tooltip-text-box.module.scss';

const bem = bemModule(styles);

export type TooltipTextBoxProps = {
    extraPadding?: boolean;
    fontSize?: 'big' | 'middle' | 'small';
    fontWeightTitle?: 'bold' | 'normal';
    hasFullWidth?: boolean;
    hasHyphen?: boolean;
    htmlText: string;
    htmlTitle?: null | string;
    linkText?: string;
    linkUrl?: string;
    withCloseButton?: boolean;
};

export default function TooltipTextBox({
    extraPadding = false,
    fontSize = 'middle',
    fontWeightTitle = 'normal',
    hasFullWidth = false,
    hasHyphen = false,
    htmlText,
    htmlTitle,
    linkText,
    linkUrl,
    withCloseButton = false,
}: TooltipTextBoxProps) {
    return (
        <div
            className={`flex-column ${bem(styles.tooltipContentContainer, {
                extraPadding,
                hasFullWidth,
                withCloseButton,
            })}`}
        >
            {htmlTitle && (
                <div className={bem(styles.htmlTitle, { fontSize: fontSize, fontWeight: fontWeightTitle })}>
                    <HtmlTextWrapper htmlText={htmlTitle} />
                </div>
            )}

            <div className={bem(styles.text, { fontSize: fontSize, hasHyphen, withCloseButton })}>
                <HtmlTextWrapper htmlText={htmlText} />
            </div>

            {linkUrl && linkText && (
                <div className={styles.link}>
                    <Link
                        href={linkUrl}
                        linkType={'external'}
                        target={'_blank'}
                    >
                        {linkText}
                    </Link>
                </div>
            )}
        </div>
    );
}
